@use '../setup';

#client {
  opacity: 0;
  transition: 1s;
  padding: 50px 0;
  .back {
    text-decoration: none;
    h5 {
      margin-bottom: -20px;
      margin-left: 80px;
      color: white;
      font-size: 18px;
      transition: 0.5s;

      &:hover {
        color: rgb(189, 189, 189);
      }
    }
  }

  .clients {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .client {
      margin-top: 40px;
      width: 400px;
      cursor: pointer;
      opacity: 1;
      transition: 0.5s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

#modal {
  display: none;
  position: fixed;
  z-index: 99;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);

  #close-modal {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 24px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 90%;
    max-width: 700px;
  }
}
