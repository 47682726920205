@use "setup";

#loader {
  background-color: rgba(171, 171, 171, 0.1);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin-top: -40px;
    font-size: 12px;
    color: white;
  }

  .divLoader {
    transform: scale(0.5);
    margin-top: -30px;
  }
}

#loader2 {
  background-color: #2f2f2f;
  border-radius: 10px;
  width: 120px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;

  .divLoader {
    transform: scale(0.5);
    margin-top: -30px;
  }

  p {
    text-align: center;
    margin-top: -40px;
    font-size: 12px;
    color: white;
  }
}
