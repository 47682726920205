// Font
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap");

//Color
$primaryColor: #171717;
$secondaryColor: #2f2f2f;

//Styles
body {
  font-family: "Noto Sans", sans-serif;
  background-color: rgba(#171717, 1);
}
