@use "../setup";

#slideshow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -130px;
  opacity: 0;
  transition: 1s;
  height: 100vh;
  #carousel {
    z-index: 1;
    ol {
      li {
        .indicator span {
          display: block;
          width: 30px;
          height: 4px;
          background-color: whitesmoke;
          cursor: pointer;
        }
      }
    }
  }

  .buttons {
    width: 360px;
    z-index: 5;
    margin-top: -100px;
    display: flex;
    justify-content: space-around;
    .btn-explore {
      width: 160px;
      border: 1px solid white;
      background-color: #2f2f2f;
      cursor: pointer;
      transition: 0.3s;
      span {
        color: white;
      }

      &:hover {
        background-color: whitesmoke;
        span {
          color: #2f2f2f;
        }
      }
    }

    .btn-contact {
      width: 160px;
      border: 1px solid white;
      background-color: #2f2f2f;
      cursor: pointer;
      transition: 0.3s;
      span {
        color: white;
      }

      &:hover {
        background-color: whitesmoke;
        span {
          color: #2f2f2f;
        }
      }
    }
  }

  @media (max-width: 992px) {
    background-image: url("../assets/img/BG1.jpg");
    background-size: cover;
    height: 100vh;
    background-position-x: 82%;
    margin-top: -100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #carousel {
      display: none;
    }

    .buttons {
      position: relative;
      margin-top: 450px;
      justify-content: center;
      .btn-contact {
        margin-left: 10px;
        width: 140px;
      }
      .btn-explore {
        width: 140px;
        margin-right: 10px;
      }
    }
  }
}
