@use "setup";

#navbar {
  margin-top: 20px;
  height: 80px;
  padding: 0;
  z-index: 10;
  position: relative;
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo img {
      width: 120px;
    }

    .links {
      width: 700px;
      height: 80px;
      padding: 0;
      display: none;
      flex-direction: column;
      justify-content: center;

      ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;

          a {
            line-height: 100px;
            text-decoration: none;
            color: whitesmoke;
            transition: 0.5s;

            &:hover {
              color: #2f2f2f;
            }
          }

          .link.active {
            color: rgba(171, 171, 171, 0.5);
          }
        }
      }
    }

    .sidebar {
      position: relative;
      z-index: 2;

      .links {
        position: fixed;
        right: 0;
        top: 0;
        display: block;
        width: 0%;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
        transform: translate(0, 30);

        &.active {
          width: 50%;
          visibility: visible;
          transform: translate(0, 0);
          opacity: 1;
        }
        ul {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding: 40px 0;
          background: rgba(#ababab, 1);
          height: 100vh;
          li {
            .link.active {
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 25px;
        cursor: pointer;
        position: absolute;
        right: 2%;
        top: -10px;
        z-index: 3;
      }

      .hamburger span {
        display: block;
        width: 30px;
        height: 4px;
        background-color: whitesmoke;
        border-radius: 36px;
        transition: 0.7s;
      }

      .hamburger .line1 {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
      }

      .hamburger .line3 {
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
      }

      .hamburger span.show1 {
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: white;
      }

      .hamburger span.show3 {
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
        -webkit-transform: rotate(-45deg) translate(0, 0);
        transform: rotate(-45deg) translate(0, 0);
        background-color: white;
      }

      .hamburger span.show2 {
        opacity: 0;
        transition: all 0.7s;
      }
    }
  }

  @media (min-width: 992px) {
    .navbar {
      background: transparent;

      .logo img {
        display: none;
      }
      .links {
        display: flex;
      }

      .sidebar {
        .hamburger {
          display: none;
        }
      }
    }
  }
}
