@use "../setup";

#contacts {
  .container {
    margin-top: -80px;
    width: 960px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #2f2f2f;
    padding: 50px 30px;
    .contact {
      width: 186px;
      height: 100px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      img {
        width: 60px;
      }

      h4 {
        color: white;
        font-size: 1em;
        transition: 0.5s;
      }

      &.link {
        &:hover {
          h4 {
            color: black;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .container {
      width: 320px;
      padding: 0px;
    }
  }
}
