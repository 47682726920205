@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap");
body {
  font-family: "Noto Sans", sans-serif;
  background-color: #171717;
}

#navbar {
  margin-top: 20px;
  height: 80px;
  padding: 0;
  z-index: 10;
  position: relative;
}
#navbar .navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#navbar .navbar .logo img {
  width: 120px;
}
#navbar .navbar .links {
  width: 700px;
  height: 80px;
  padding: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
}
#navbar .navbar .links ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
}
#navbar .navbar .links ul li {
  list-style-type: none;
}
#navbar .navbar .links ul li a {
  line-height: 100px;
  text-decoration: none;
  color: whitesmoke;
  transition: 0.5s;
}
#navbar .navbar .links ul li a:hover {
  color: #2f2f2f;
}
#navbar .navbar .links ul li .link.active {
  color: rgba(171, 171, 171, 0.5);
}
#navbar .navbar .sidebar {
  position: relative;
  z-index: 2;
}
#navbar .navbar .sidebar .links {
  position: fixed;
  right: 0;
  top: 0;
  display: block;
  width: 0%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translate(0, 30);
}
#navbar .navbar .sidebar .links.active {
  width: 50%;
  visibility: visible;
  transform: translate(0, 0);
  opacity: 1;
}
#navbar .navbar .sidebar .links ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0;
  background: #ababab;
  height: 100vh;
}
#navbar .navbar .sidebar .links ul li .link.active {
  color: rgba(0, 0, 0, 0.5);
}
#navbar .navbar .sidebar .hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 2%;
  top: -10px;
  z-index: 3;
}
#navbar .navbar .sidebar .hamburger span {
  display: block;
  width: 30px;
  height: 4px;
  background-color: whitesmoke;
  border-radius: 36px;
  transition: 0.7s;
}
#navbar .navbar .sidebar .hamburger .line1 {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
#navbar .navbar .sidebar .hamburger .line3 {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#navbar .navbar .sidebar .hamburger span.show1 {
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: white;
}
#navbar .navbar .sidebar .hamburger span.show3 {
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: rotate(-45deg) translate(0, 0);
  transform: rotate(-45deg) translate(0, 0);
  background-color: white;
}
#navbar .navbar .sidebar .hamburger span.show2 {
  opacity: 0;
  transition: all 0.7s;
}
@media (min-width: 992px) {
  #navbar .navbar {
    background: transparent;
  }
  #navbar .navbar .logo img {
    display: none;
  }
  #navbar .navbar .links {
    display: flex;
  }
  #navbar .navbar .sidebar .hamburger {
    display: none;
  }
}

#slideshow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -130px;
  opacity: 0;
  transition: 1s;
  height: 100vh;
}
#slideshow #carousel {
  z-index: 1;
}
#slideshow #carousel ol li .indicator span {
  display: block;
  width: 30px;
  height: 4px;
  background-color: whitesmoke;
  cursor: pointer;
}
#slideshow .buttons {
  width: 360px;
  z-index: 5;
  margin-top: -100px;
  display: flex;
  justify-content: space-around;
}
#slideshow .buttons .btn-explore {
  width: 160px;
  border: 1px solid white;
  background-color: #2f2f2f;
  cursor: pointer;
  transition: 0.3s;
}
#slideshow .buttons .btn-explore span {
  color: white;
}
#slideshow .buttons .btn-explore:hover {
  background-color: whitesmoke;
}
#slideshow .buttons .btn-explore:hover span {
  color: #2f2f2f;
}
#slideshow .buttons .btn-contact {
  width: 160px;
  border: 1px solid white;
  background-color: #2f2f2f;
  cursor: pointer;
  transition: 0.3s;
}
#slideshow .buttons .btn-contact span {
  color: white;
}
#slideshow .buttons .btn-contact:hover {
  background-color: whitesmoke;
}
#slideshow .buttons .btn-contact:hover span {
  color: #2f2f2f;
}
@media (max-width: 992px) {
  #slideshow {
    background-image: url("../assets/img/BG1.jpg");
    background-size: cover;
    height: 100vh;
    background-position-x: 82%;
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #slideshow #carousel {
    display: none;
  }
  #slideshow .buttons {
    position: relative;
    margin-top: 450px;
    justify-content: center;
  }
  #slideshow .buttons .btn-contact {
    margin-left: 10px;
    width: 140px;
  }
  #slideshow .buttons .btn-explore {
    width: 140px;
    margin-right: 10px;
  }
}

#jumbotron-portfolio {
  opacity: 0;
  position: relative;
  transition: 1s;
}
#jumbotron-portfolio .jumbotron {
  margin-top: 30px;
  background-color: #171717;
  background-image: url("../assets/img/Jumbo_portfolio.jpg");
  background-size: cover;
  height: 100vh;
  background-position-x: 50%;
}
#jumbotron-portfolio .jumbotron .row {
  display: flex;
}
#jumbotron-portfolio .jumbotron .row h1 {
  font-family: "Noto Sans JP", sans-serif;
  color: whitesmoke;
  font-weight: 100;
  font-size: 36px;
  letter-spacing: 20px;
  margin: auto;
  margin-top: 70px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  #jumbotron-portfolio .jumbotron {
    margin-top: 0px;
  }
  #jumbotron-portfolio .jumbotron .row h1 {
    font-size: 24px;
  }
}

#menus {
  margin-top: -120px;
  position: relative;
}
#menus .menus {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#menus .menus .menu {
  margin-top: 50px;
  width: 360px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#menus .menus .menu img {
  width: 100%;
}
#menus .menus .menu .btn-menu {
  background-color: #2f2f2f;
  color: white;
  width: 270px;
  height: 52px;
  border: 1px solid white;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 5px;
  transition: 0.5s;
}
#menus .menus .menu .btn-menu:hover {
  background-color: white;
  color: #2f2f2f;
  border: 1px solid black;
}
@media (max-width: 576px) {
  #menus {
    margin-top: -140px;
  }
  #menus .menus .menu {
    margin-top: 30px;
    width: 320px;
    height: 400px;
    justify-content: center;
  }
  #menus .menus .menu .btn-menu {
    margin-top: -30px;
  }
}
#menus hr {
  margin-top: 60px;
  margin-bottom: 30px;
  height: 1px;
  background-color: #454545;
}

#category .category-panel .row {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#category .category-panel .row h2 {
  color: white;
  letter-spacing: 5px;
}
#category .category-panel .row h6 {
  transition: 0.5s;
  color: white;
  cursor: pointer;
}
#category .category-panel .row h6:hover {
  color: #ababab;
}
#category .category-panel .contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#category .category-panel .contents .content {
  width: 480px;
  height: 372px;
  margin-top: 40px;
}
#category .category-panel .contents .content .thumbnail {
  width: 480px;
  height: 320px;
  opacity: 1;
  transition: 0.5s;
}
#category .category-panel .contents .content .thumbnail:hover {
  opacity: 0.5;
}
#category .category-panel .contents .content .thumbnail img {
  width: 100%;
  height: 320px;
  object-fit: contain;
}
#category .category-panel .contents .content .btn-detail {
  text-decoration: none;
}
#category .category-panel .contents .content .btn-detail h3 {
  margin-top: 10px;
  text-align: center;
  transition: 0.5s;
  color: white;
  cursor: pointer;
}
#category .category-panel .contents .content .btn-detail h3:hover {
  color: #ababab;
}
@media (max-width: 575.8px) {
  #category .category-panel .row {
    display: block;
    padding: 0 20px;
    margin-bottom: -10px;
  }
  #category .category-panel .row h6 {
    display: none;
  }
  #category .category-panel .contents .content .btn-detail h3 {
    font-size: 1.3rem;
  }
}

#client {
  opacity: 0;
  transition: 1s;
  padding: 50px 0;
}
#client .back {
  text-decoration: none;
}
#client .back h5 {
  margin-bottom: -20px;
  margin-left: 80px;
  color: white;
  font-size: 18px;
  transition: 0.5s;
}
#client .back h5:hover {
  color: #bdbdbd;
}
#client .clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#client .clients .client {
  margin-top: 40px;
  width: 400px;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s;
}
#client .clients .client:hover {
  opacity: 0.5;
}

#modal {
  display: none;
  position: fixed;
  z-index: 99;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  /* Modal Content (image) */
}
#modal #close-modal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 24px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
#modal .modal-content {
  margin: auto;
  display: block;
  width: 90%;
  max-width: 700px;
}

#jumbotron-about {
  opacity: 0;
  transition: 1s;
}
#jumbotron-about .jumbotron {
  margin-top: 30px;
  background-color: #171717;
  background-image: url("../assets/img/Jumbo_about.jpg");
  background-size: cover;
  height: 100vh;
  background-position-x: 40%;
}
#jumbotron-about .jumbotron .row {
  display: flex;
}
#jumbotron-about .jumbotron .row h1 {
  font-family: "Noto Sans JP", sans-serif;
  color: whitesmoke;
  font-weight: 100;
  font-size: 36px;
  letter-spacing: 20px;
  margin: auto;
  margin-top: 70px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  #jumbotron-about .jumbotron {
    margin-top: 0px;
  }
  #jumbotron-about .jumbotron .row h1 {
    font-size: 24px;
  }
}

#about .container {
  margin-top: -80px;
}
#about .container .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#about .container .row .logo {
  width: 480px;
}
#about .container .row .logo img {
  width: 100%;
}
#about .container .row .description {
  background-color: #2f2f2f;
  padding: 30px;
  width: 480px;
  display: flex;
  flex-direction: column;
}
#about .container .row .description p {
  margin: auto;
  font-family: "Rokkitt", serif;
  color: white;
}

#jumbotron-contact {
  opacity: 0;
  transition: 1s;
}
#jumbotron-contact .jumbotron {
  margin-top: 30px;
  background-color: #171717;
  background-image: url("../assets/img/Jumbo_contact.jpg");
  background-size: cover;
  height: 100vh;
  background-position-x: 50%;
}
#jumbotron-contact .row {
  display: flex;
}
#jumbotron-contact .row h1 {
  font-family: "Noto Sans JP", sans-serif;
  color: whitesmoke;
  font-weight: 100;
  font-size: 36px;
  letter-spacing: 20px;
  margin: auto;
  margin-top: 70px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  #jumbotron-contact .jumbotron {
    margin-top: 0px;
  }
  #jumbotron-contact .jumbotron .row h1 {
    font-size: 24px;
  }
}

#contacts .container {
  margin-top: -80px;
  width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #2f2f2f;
  padding: 50px 30px;
}
#contacts .container .contact {
  width: 186px;
  height: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
#contacts .container .contact img {
  width: 60px;
}
#contacts .container .contact h4 {
  color: white;
  font-size: 1em;
  transition: 0.5s;
}
#contacts .container .contact.link:hover h4 {
  color: black;
}
@media (max-width: 576px) {
  #contacts .container {
    width: 320px;
    padding: 0px;
  }
}

#loader {
  background-color: rgba(171, 171, 171, 0.1);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#loader p {
  text-align: center;
  margin-top: -40px;
  font-size: 12px;
  color: white;
}
#loader .divLoader {
  transform: scale(0.5);
  margin-top: -30px;
}

#loader2 {
  background-color: #2f2f2f;
  border-radius: 10px;
  width: 120px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
}
#loader2 .divLoader {
  transform: scale(0.5);
  margin-top: -30px;
}
#loader2 p {
  text-align: center;
  margin-top: -40px;
  font-size: 12px;
  color: white;
}

