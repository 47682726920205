@use '../setup';

#menus {
  margin-top: -120px;
  position: relative;
  .menus {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .menu {
      margin-top: 50px;
      width: 360px;
      height: 440px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        width: 100%;
      }

      .btn-menu {
        background-color: #2f2f2f;
        color: white;
        width: 270px;
        height: 52px;
        border: 1px solid white;
        font-family: "Noto Sans JP", sans-serif;
        font-size: 20px;
        font-weight: 100;
        letter-spacing: 5px;
        transition: 0.5s;
        &:hover {
          background-color: white;
          color: #2f2f2f;
          border: 1px solid black;
        }
      }
    }
  }

  @media (max-width: 576px) {
    margin-top: -140px;

    .menus {
      .menu {
        margin-top: 30px;
        width: 320px;
        height: 400px;
        justify-content: center;

        .btn-menu {
          margin-top: -30px;
        }
      }
    }
  }

  hr {
    margin-top: 60px;
    margin-bottom: 30px;
    height: 1px;
    background-color: #454545;
  }
}
