@use "../setup";

#category {
  .category-panel {
    .row {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: white;
        letter-spacing: 5px;
      }
      h6 {
        transition: 0.5s;
        color: white;
        cursor: pointer;
        &:hover {
          color: #ababab;
        }
      }
    }

    .contents {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content {
        width: 480px;
        height: 372px;
        margin-top: 40px;

        .thumbnail {
          width: 480px;
          height: 320px;
          opacity: 1;
          transition: 0.5s;
          &:hover {
            opacity: 0.5;
          }

          img {
            width: 100%;
            height: 320px;
            object-fit: contain;
          }
        }

        .btn-detail {
          text-decoration: none;
          h3 {
            margin-top: 10px;
            text-align: center;
            transition: 0.5s;
            color: white;
            cursor: pointer;
            &:hover {
              color: #ababab;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575.8px) {
    .category-panel {
      .row {
        display: block;
        padding: 0 20px;
        margin-bottom: -10px;
        h6 {
          display: none;
        }
      }

      .contents {
        .content {
          .btn-detail {
            h3 {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
